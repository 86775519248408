import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import ElementUI from 'element-ui';
import lang from 'element-ui/lib/locale/lang/ja'
import locale from 'element-ui/lib/locale'
import 'element-ui/lib/theme-chalk/index.css';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';


// 构建时产品的提示信息
Vue.config.productionTip = false

// 完整引入ElementUI
Vue.use(ElementUI);
// 完整引入Antd
Vue.use(Antd)

locale.use(lang)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

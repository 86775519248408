import Vue from 'vue'
import Vuex from 'vuex'

//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
  state: {
    //存放的键值对就是所要管理的状态
    full_name: localStorage.getItem('full_name') ? localStorage.getItem('full_name') : '',

    user_name: localStorage.getItem('user_name') ? localStorage.getItem('user_name') : '',

    // 存储token
    Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',

    //存储本人验证成功后的状态
    active: '',
  },
  getters: {
    full_name() {
      return localStorage.getItem('full_name')
    },

    getActive(state) {
      return state.active
    },
  },
  mutations: {
    // 修改token，并将token存入localStorage
    setToken(state, loginResInfo) {
      state.Authorization = loginResInfo.Authorization
      state.full_name = loginResInfo.full_name
      state.user_name = loginResInfo.user_name
      localStorage.setItem('Authorization', loginResInfo.token);
      localStorage.setItem('full_name', loginResInfo.full_name);
      localStorage.setItem('user_name', loginResInfo.user_name);
    },

    deleteToken(state) {
      state.Authorization.removeItem
      state.full_name.removeItem
      state.user_name.removeItem
      localStorage.removeItem('Authorization')
      localStorage.removeItem('full_name')
      localStorage.removeItem('user_name')
    },

    setActive(state, activeInfo) {
      state.active = activeInfo
    }
  },
  actions: {},
  modules: {},

})

export default store

// 配置路由相关信息
import Vue from 'vue'
import VueRouter from 'vue-router'


// 1.通过Vue.use(插件)，安装插件
Vue.use(VueRouter)

//解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 2.创建VueRouter对象
const routes = [
  {
    path: '/SignIn',
    name: 'SignIn',
    component: () => import('views/sign/SignIn'),
    hidden: true,
    meta: {
      title: 'ログイン'
    }
  },
  {
    path: '/SignUp',
    name: 'SignUp',
    component: () => import('views/sign/SignUp'),
    meta: {
      title: '登録'
    }
  },
  {
    path: '/Active',
    name: 'Active',
    component: () => import('views/sign/Active'),
    meta: {
      title: '認証'
    }
  },
  {
    path: '/Meeting',
    name: 'Meeting',
    component: () => import('views/sign/Meeting'),
    meta: {
      title: 'Meeting'
    }
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: () => import('views/sign/ResetPassword'),
    meta: {
      title: 'PWReset'
    }
  },
  {
    path: '/PasswordInput',
    name: 'PasswordInput',
    component: () => import('views/sign/PasswordInput'),
    meta: {
      title: 'PWInput'
    }
  },
  {
    path: '',
    name: 'index',
    component: () => import('views/index'),
    meta: {
      title: 'index'
    },
    redirect: '/ProjectList',
    children: [
      {
        path: '/ProjectList',
        name: 'ProjectList',
        component: () => import('views/project/ProjectList'),
        meta: {
          title: '案件一覧'
        }
      },
      {
        path: '/EstimateList',
        name: 'EstimateList',
        component: () => import('views/project/EstimateList'),
        meta: {
          title: '見積一覧'
        }
      },
      {
        path: '/InvoiceList',
        name: 'InvoiceList',
        component: () => import('views/project/InvoiceList'),
        meta: {
          title: '請求一覧'
        }
      },
      {
        path: '/ProjectForm',
        name: 'ProjectForm',
        component: () => import('views/project/ProjectForm'),
        meta: {
          title: '案件詳細'
        }
      },
      {
        path: '/EstimateForm',
        name: 'EstimateForm',
        component: () => import('views/project/EstimateForm'),
        meta: {
          title: '見積'
        }
      },
      {
        path: '/OrderForm',
        name: 'OrderForm',
        component: () => import('views/project/OrderForm'),
        meta: {
          title: '注文'
        }
      },
      {
        path: '/DeliveryForm',
        name: 'DeliveryForm',
        component: () => import('views/project/DeliveryForm'),
        meta: {
          title: '納品'
        }
      },
      {
        path: '/InvoiceDetailsTable',
        name: 'InvoiceDetailsTable',
        component: () => import('components/content/project/InvoiceDetailsTable'),
        meta: {
          title: '請求詳細'
        }
      },
      {
        path: '/EstimateDetailsTable',
        name: 'EstimateDetailsTable',
        component: () => import('components/content/project/EstimateDetailsTable'),
        meta: {
          title: '見積詳細'
        }
      },
      {
        path: '/InvoiceForm',
        name: 'InvoiceForm',
        component: () => import('views/project/InvoiceForm'),
        meta: {
          title: '請求'
        }
      },
      {
        path: '/AcceptanceForm',
        name: 'AcceptanceForm',
        component: () => import('views/project/AcceptanceForm'),
        meta: {
          title: '支払'
        }
      },
      {
        path: '/CustomerList',
        name: 'CustomerList',
        component: () => import('views/master/CustomerList'),
        meta: {
          title: '顧客一覧'
        }
      },
      {
        path: '/ProductForm',
        name: 'ProductForm',
        component: () => import('views/master/ProductForm'),
        meta: {
          title: '商品詳細'
        }
      },
      {
        path: '/ProductList',
        name: 'ProductList',
        component: () => import('views/master/ProductList'),
        meta: {
          title: '商品一覧'
        }
      },
      {
        path: '/CategoryForm',
        name: 'CategoryForm',
        component: () => import('views/master/CategoryForm'),
        meta: {
          title: 'カテゴリー'
        }
      },
      {
        path: '/CategoryList',
        name: 'CategoryList',
        component: () => import('views/master/CategoryList'),
        meta: {
          title: 'カテゴリー'
        }
      },
      {
        path: '/PdfView',
        name: 'PdfView',
        component: () => import('views/project/PdfView'),
        meta: {
          title: 'PDFレビュー'
        }
      },
      {
        path: '/Settings',
        name: 'Settings',
        component: () => import('views/project/Settings'),
        meta: {
          title: '設定'
        }
      }]
  }
]

const router = new VueRouter({
  // 配置路由和组件之间的应用关系
  routes
})


router.beforeEach((to, from, next) => {
  if (to.name === 'SignIn' || to.name === 'SignUp' || to.name === 'ResetPassword' || to.name === 'PasswordInput' || to.name === 'Active') {
    next()
  } else {
    let token = localStorage.getItem('Authorization');
    if (token === null || token === '') {
      next({ name: 'SignIn' })
    } else {
      next();
    }
  }
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})

// 3.将router对象传入到Vue实例
export default router


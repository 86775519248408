<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
// import index from 'views/index.vue'

export default {
  name: 'App'
}
</script>

<style>
@import "assets/css/base.css";
</style>
